<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? $t("AJOUTER_UN") : $t("MODIFICATION_DU") }} {{$t("SITE")}}  </h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>
      <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
        <div class="p-6">
          <p class="pt-4">{{ $t("site") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="site"
            v-model="input.name"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('site')">{{$t("site_error")}}</span>

          <p class="pt-4">{{ $t("longitude") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input
          @blur="validateCoordinates_longitude"
            type="number"
            v-validate="'required'"
            :min="0"
            data-vv-validate-on="blur"
            name="longitude"
            v-model="input.longitude"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('longitude')">{{$t("site_longitude")}}</span>
          <br v-if="!isValid_longitude">
          <span class="text-danger text-sm" v-if="!isValid_longitude" >Longitude invalide.</span>

          <p class="pt-4">{{ $t("latitude") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input
          @blur="validateCoordinates_latitude"
            v-validate="'required'"
            type="number"
            :min="0"
            data-vv-validate-on="blur"
            name="latitude"
            v-model="input.latitude"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('latitude')">{{$t("site_latitude")}}</span>
          <br v-if="!isValid_latitude">
          <span class="text-danger text-sm" v-if="!isValid_latitude" >Latitude invalide.</span>
         <!--  <p class="pt-4">{{ $t("zone") }}<b style="color: #ff6141" >*</b> </p>
              <vs-select
                v-model="input.zone"
                data-vv-validate-on="blur"
                v-validate="'required'"
                autocomplete
                name="security_level"
                class="w-full"
            >
          <vs-select-item :key="item" :value="item.id" :text="item.name" v-for="item in zones" /></vs-select>
          
          <span class="text-danger text-sm" v-show="errors.has('security_level')">{{$t("zone_error")}}</span>
 -->
          <p class="pt-4">{{ $t("reserve_type") }}<b style="color: #ff6141" >*</b> </p>
          <v-select
            v-validate="'required'"
            name="reserve_type"
            :reduce="rep => rep.key"
            class="w-full" label="name"
            v-model="input.reserve_type"
            :options="reserve_choices">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('reserve_type')">{{$t("reserve_type_error")}}</span>

          <p  v-if="input.reserve_type !=='aucune'" class="pt-4">{{ $t("reserve_comment") }}<b style="color: #ff6141" >*</b> </p>
          <vs-textarea
          v-if="input.reserve_type !=='aucune'"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="reserve_comment"
            v-model="input.reserve_comment"
            rows="3"
            class="w-full" />
            <span  v-if="input.reserve_type !=='aucune'" class="text-danger text-sm" v-show="errors.has('reserve_comment')">{{$t("reserve_comment_error")}}</span>
        
            </div>
      </component>
  
      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button :disabled="!isValid_longitude||!isValid_latitude" class="mr-6" @click="category_validate">{{$t("Soumettre")}}</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Annuler")}}</vs-button>
      </div>
    </vs-sidebar>
  </template>
  
<script>
  
// For custom error message
const isValidCoordinates = require('is-valid-coordinates')
import { Validator } from 'vee-validate'
  
const dict = {
  custom: {
    reserve_type: {
      required: 'Le champ type de reserve est obligatoire'
    },
    reserve_comment: {
      required: 'Le champ commentaire est obligatoire'
    },
    site: {
      required: 'Le champ site est obligatoire'
    },
    zone: {
      required: 'Le champ zone est obligatoire'
    },
    longitude: {
      required: 'Le champ longitude est obligatoire'
    },
    latitude: {
      required: 'Le champ latitude est obligatoire'
    }
  }
}
  
// register custom messages
Validator.localize('en', dict)
  
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  
const input_tempon = {
  name: '',
  //zone:'',
  reserve_type:'',
  reserve_comment:'',
  longitude:0,
  latitude:0
}
  
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      //zones:[],
      isValid_longitude: true,
      isValid_latitude: true,
      reserve_choices: [
        {
          key: 'aucune',
          name: 'Aucune'
        },
        {
          key: 'mineure',
          name: 'Mineure'
        },
        {
          key: 'bloquante',
          name: 'Bloquante'
        }
      ],
      input: JSON.parse(JSON.stringify(input_tempon)),
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        this.input = JSON.parse(JSON.stringify(this.data))
        //this.input.zone = JSON.parse(JSON.stringify(this.data.zone.id))

        this.initValues()
      }
    }
  },
  
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    validateCoordinates_longitude () {
      this.isValid_longitude = isValidCoordinates.longitude(parseFloat(this.input.longitude))
    },
    validateCoordinates_latitude () {
      this.isValid_latitude = isValidCoordinates.latitude(parseFloat(this.input.latitude))
    },
    initValues () {
      if (this.data.id) return
      this.input = JSON.parse(JSON.stringify(input_tempon))
      this.isValid_longitude = true
      this.isValid_latitude = true
    },
    category_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
  
    async submitData () {
      this.$vs.loading()
      const input = JSON.parse(JSON.stringify(this.input))
      if (input.reserve_type === 'aucune') {
        input.reserve_comment = ''
      }
      let url = 'sites/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('site_save')
      }
      if (input.id) {
        url += `${input.id}/`
        methods = 'put'
        message.success = this.$t('site_update')
      }
  
      this.$http[methods](url, input)
        .then((response) => {
          window.sites.getAllsites()
          window.getPrendTaCom.success(message.success, response)
          this.$emit('closeSidebar')
          this.initValues()
        })
        .catch((error) => {
  
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            let libelle = ''
            if (item === 'name') {
              libelle = this.$t('site')
            }
            for (let j = 0; j < error.response.data[item].length; j++) {
              window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
            }
          }
  
          window.getPrendTaCom.error(message.error)
        })
    }
  /*   getAllzones () {
      this.$vs.loading()
      this.$http.get('zones/')
        .then((response) => {
          this.zones = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    } */
  
  },
  created () {
    //this.getAllzones()

  }
}
</script>
  
  <style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }
  
    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;
  
      .img-upload {
        margin-top: 2rem;
  
        .con-img-upload {
          padding: 0;
        }
  
        .con-²-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
  
  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  
    &:not(.ps) {
      overflow-y: auto;
    }
  }
  </style>
  